<template>
  <div id="main-layout">
    <section>
      <div class="main-title" v-if="!isPhone">
        <h1 @click="gotoHome">VFASHION</h1>
      </div>
      <div class="menubar-header" id="nav">
        <template v-if="isPhone">
          <v-menu>
            <template v-slot:activator="{ props }">
              <v-btn color="#c3e3ce" v-bind="props">
                <img
                  style="width: 20px; height: 20px"
                  src="../assets/images/menu-icon.png"
                />
              </v-btn>
            </template>
            <v-list class="phone-menus">
              <v-list-item
                v-for="(item, index) in menuList"
                :key="index"
                :value="index"
                @click="selectType(item.id)"
              >
                <v-list-item-title
                  :style="{
                    color: selectedType === item.id ? 'red' : 'black',
                  }"
                  >{{ item.name }}</v-list-item-title
                >
              </v-list-item>
            </v-list>
          </v-menu>
          <div class="main-title-phone" @click="gotoHome">VFASHION</div>
          <div></div>
        </template>
        <div class="menubar" v-else>
          <div
            class="menu-item"
            v-for="(item, index) of menuList"
            :key="index"
            @click="selectType(item.id)"
          >
            <span class="menu-link-channel menu-link-fashion">
              <a class="text" :class="{ active: selectedType === item.id }">{{
                item.ename
              }}</a>
              <a class="hover-text">{{ item.name }}</a>
            </span>
          </div>
        </div>
      </div>
      <div class="container">
        <slot></slot>
      </div>
    </section>
    <div class="footer">
      <div class="bottom-right">
        <div
          class="div-right"
          :style="{ width: isPhone ? screenWidth + 'px' : '' }"
        >
          <span class="span-links" v-if="!isPhone">
            <a class="links">关于我们</a><a class="line"></a>
            <a class="links">联系我们</a><a class="line"></a>
            <a class="links">版权声明</a><a class="line"></a>
            <a class="links">隐私条款</a><a class="line"></a>
            <a class="links">意见反馈</a><a class="line"></a>
            <a class="links">友情链接</a>
          </span>
          <span class="span-right">
            CopyRight &copy; 2023 上海聚萌文化传播有限公司

            <!-- <a class="police">苏公网安备 xxxxxx号</a> -->
            <!-- <a>
              <img
                src="//cdn.yoho.cn/yohobuy-node/6.1.16/img/layout/ebsIcon.png?15fc8919c64"
              />
            </a> -->
          </span>
          <span class="span-right" :style="{ marginLeft: isPhone ? '' : '8px' }"
            >沪ICP备2023026330号-1</span
          >
        </div>
      </div>
    </div>
  </div>
</template>
<script setup>
import { onMounted, ref } from "vue";
import { getCategory } from "@/api/article";
import { useRouter, useRoute } from "vue-router";

const menuList = ref([]);
const selectedType = ref(null);
const router = useRouter();
const emits = defineEmits(["categoryChange", "menu"]);
const isPhone = ref(false);
const screenWidth = window.innerWidth;

onMounted(() => {
  isPhone.value = screenWidth <= 768 ? true : false;
  const navbar = document.getElementById("nav");
  const sticky = navbar.offsetTop;
  // if (!isPhone.value) {
  // 导航栏吸顶
  window.onscroll = function () {
    if (window.scrollY >= sticky) {
      navbar.classList.add("sticky");
    } else {
      navbar.classList.remove("sticky");
    }
  };
  // }
  getCategory().then((res) => {
    // console.log(res.data);
    menuList.value = res.data;
    emits("menu", menuList.value);
  });
});

const gotoHome = () => {
  router.push(`/home`);
};
const selectType = (id) => {
  selectedType.value = id;
  emits("categoryChange", id);
};
const setActiveType = (id) => {
  selectedType.value = id;
};
defineExpose({ setActiveType });
</script>
<style lang="less" scoped>
#main-layout {
  min-height: 100vh;
  width: 100%;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  .main-title {
    margin: 40px 0;
    h1 {
      font-size: 40px;
      font-family: "futura", "helvetica", "黑体";
      cursor: pointer;
    }
  }
  .menubar-header {
    width: 100%;
    background-color: #c3e3ce;
    .menubar {
      height: 43px;
      width: 1080px;
      line-height: 43px;
      font-size: 12px;
      margin: 0 auto;
      position: relative;
      display: flex;
      justify-content: space-between;

      .menu-item {
        display: block;
        height: 43px;
        line-height: 43px;
        float: left;
        cursor: pointer;
        .menu-link-channel {
          font-size: 20px;
          font-family: "futura", "helvetica", "黑体";
          position: relative;
          letter-spacing: 1px;
          line-height: 43px;
          color: #000;
          display: block;
          height: 100%;
          .active {
            color: white !important;
          }

          &:hover {
            .text {
              display: none;
            }
            .hover-text,
            .text-name {
              display: block;
              color: white;
            }
          }
          .text,
          .text-name {
            display: block;
            text-align: center;
            color: #000;
          }
          .hover-text {
            display: none;
          }
        }
        .menu-link {
          &-fashion {
            width: 100px;
          }
          &-beauty {
            width: 100px;
            margin-left: 21px;
          }
          &-lifestyle {
            width: 100px;
            margin-left: 11px;
          }
          &-video {
            width: 100px;
            margin-left: 14px;
          }
          &-magazine {
            padding-right: 18px;
            margin-left: 22px;
            width: 147px;
            background: url(../assets/images/magazine_icon.png) top right
              no-repeat;
            &:hover {
              background: url(../assets/images/magazine_icon.png) right -55px no-repeat;
            }
          }
        }
      }
    }
  }
  .bottom-content {
    border-top: 1px dotted #999;
    border-bottom: 1px dotted #999;
    width: 1097px;
    height: 200px;
    margin: 60px auto 0 auto;
    .footer-container {
      width: 68%;
      margin: 0 auto;
    }
    .footer-icon {
      height: 200px;
      display: inline-block;
      width: 20%;
      float: left;
    }
    .phone-icon {
      background-image: url(../assets/images/phone-icon.png);
      background-size: 120px 120px;
      background-position: 100% center;
      background-repeat: no-repeat;
    }
    .y-icon {
      width: 28%;
      text-align: center;
      position: relative;
      img {
        width: 90px;
        height: 90px;
        border-radius: 1.4rem;
        position: absolute;
        top: 50%;
        right: 38px;
        margin-top: -45px;
      }
    }
    .split-v {
      width: 4%;
      background-image: url(../assets/images/split-v.png);
      background-size: 3px 100px;
      background-position: center center;
      background-repeat: no-repeat;
    }
    .appqr-icon {
      width: 20%;
      background-image: url(../assets/images/yohogirlqr.png);
      background-size: 90px 90px;
      background-position: center center;
      background-repeat: no-repeat;
    }
    .text-icon {
      line-height: 155px;
      width: 24%;
      padding-left: 20px;
      h2 {
        font-size: 18px;
        height: 25px;
        margin: 0;
        text-align: left;
      }
      p {
        height: 16px;
        color: #737373;
        font-size: 12px;
        margin: 0;
        text-align: left;
      }
    }
  }
  .bottom-right {
    background: #f5f5f5;
    width: 100%;
    margin-top: 60px;
    height: 110px;
    .div-right {
      width: 1080px;
      height: 82px;
      padding-top: 8px;
      margin: 20px auto 0 auto;
      .span-links {
        display: block;
        width: 520px;
        height: 20px;
        margin: 28px auto 0 auto;
        font-size: 16px;
        font-family: "helvetica", "futura", "黑体";
        color: #999;
        .links {
          color: #333333;
          display: block;
          float: left;
          height: 20px;
          line-height: 20px;
          margin: 0 5px;
          cursor: pointer;
          text-decoration: none;
        }
        .line {
          display: block;
          float: left;
          width: 2px;
          height: 20px;
          background: #333333;
          margin: 0 5px;
        }
      }
      .span-right {
        line-height: 30px;
        display: inline-block;
        height: 30px;
        margin: 0 auto;
        text-align: center;
        color: #999;
        font-size: 12px;
        a {
          margin-left: 4px;
        }
        .police {
          padding-left: 25px;
          background: url(../assets/images/police.png) no-repeat;
          color: #999;
          display: inline-block;
        }
      }
    }
  }
}

section {
  flex: 1;
  width: 100%;
}
.footer {
  width: 100%;
  margin-top: auto;
}

.sticky {
  position: fixed;
  top: 0;
  z-index: 100;
}
@media screen and (max-width: 768px) {
  .menubar-header {
    padding: 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .main-title-phone {
      font-family: "futura", "helvetica", "黑体";
      font-weight: 700;
      font-size: 30px;
    }
    .phone-menus {
      background: #c3e3ce;
    }
  }
  .div-right {
    margin: 0 !important;
    display: flex;
    justify-content: center;
    flex-direction: column;
  }
}
</style>
