import request from "@/utils/request";

// 获取分类列表
export const getCategory = () => {
  return request({
    method: "GET",
    url: "category",
  });
};

// 获取某分类下所有文章
export const getArticleList = (id, pagesize, pagenum) => {
  return request({
    method: "GET",
    url: `article/list/${id}?pagesize=${pagesize}&pagenum=${pagenum}`,
  });
};

// 获取文章详情
export const getArticleDetail = (id) => {
  return request({
    method: "GET",
    url: `article/info/${id}`,
  });
};

// 获取轮播图
export const getBanner = () => {
  return request({
    method: "GET",
    url: "banner",
  });
};

// 获取最新4篇文章
export const getLatest = () => {
  return request({
    method: "GET",
    url: "article/latest",
  });
};
