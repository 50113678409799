import axios from "axios";
import { getCacheData, getCacheKey, setCacheData } from "./cache";

// baseurl 配置项
const commonBase = "http://121.40.110.102:1234/api/v1/";

// 创建 axios 实例
const request = axios.create({
  // API 请求的默认前缀
  baseURL: commonBase,
  timeout: 600000, // 请求超时时间
});

// 利用axios的cancelToken来取消请求
const CancelToken = axios.CancelToken;

// 异常拦截处理器
const errorHandler = (error) => {
  if (axios.isCancel(error)) {
    // cache缓存数据
    return Promise.resolve(error.message);
  }

  if (error.response) {
    const data = error.response.data;
    if (error.response.status === 403) {
      errorMsg(data.message);
    }
    if (error.response.status === 401) {
      errorMsg(data.message);
    }
  }
  return Promise.reject(error);
};

// 请求失败处理器
const failHandler = (resp) => {
  if (!resp) return Promise.reject();

  // if (resp.code === 401 ) {
  //   logout(resp.data)
  // }

  if (resp instanceof Blob && resp.type === "application/json") {
    // blob转化为json对象
    const reader = new FileReader();
    reader.readAsText(resp, "utf-8");
    reader.onload = function (e) {
      const readerRes = reader.result || "";
      const parseObj = JSON.parse(readerRes);
      errorMsg(parseObj.message || parseObj.msg);
    };
    return Promise.reject(resp);
  } else {
    errorMsg(resp.message || resp.msg);
    return Promise.reject(resp.message || resp.msg);
  }
};

const errorMsg = (msg) => {
  window.alert(msg);
  // notification.error({
  //   message: 'error',
  //   description: msg
  // })
};

request.interceptors.request.use((config) => {
  // const token = getSessionStorage(ACCESS_TOKEN)
  const CancelSource = CancelToken.source();
  config.cancelToken = CancelSource.token;
  // if (token) {
  //   config.headers.Authorization = token
  // }
  // 是否启用缓存
  if (config.cache) {
    const cacheKey = getCacheKey(config);
    const cacheData = getCacheData(cacheKey);
    if (cacheData) {
      CancelSource.cancel(cacheData);
    }
  }
  return config;
}, errorHandler);

// response interceptor
request.interceptors.response.use((response) => {
  if (response.data.type === "application/json") {
    return response.data;
  }

  if (response.data.msg !== "success" && response.status !== 200) {
    return failHandler(response.data);
  } else {
    if (response.config.cache && response.data.code === 200) {
      const cacheKey = getCacheKey(response.config);
      setCacheData(cacheKey, response.data);
    }
    return response.data;
  }
}, errorHandler);

export default request;
