<template>
  <MainLayout @category-change="gotoCategory" @menu="getMenu">
    <div class="banner">
      <div
        class="swiper-box"
        :style="{
          width: isPhone ? screenWidth - 30 + 'px' : '',
          height: isPhone ? (screenWidth - 30) / 2.7 + 'px' : '',
        }"
        @mouseenter="MouseFun('enter')"
        @mouseleave="MouseFun('leave')"
      >
        <!-- 图片 -->
        <img
          v-for="(item, index) in imgList"
          :class="['imgCss', ShowImg == index ? 'ShowCss' : '']"
          :src="item.url"
          :key="index"
          @click.stop="jumpDetail()"
        />
        <!-- 左箭头按钮 -->
        <div class="leftBtn" @click="throttle(PrevFun)"></div>
        <!-- 右箭头按钮 -->
        <div class="rightBtn" @click="throttle(NextFun)"></div>
        <!-- 下方指示点容器 -->
        <div class="instBox">
          <div
            v-for="(item, index) in imgList"
            :key="index"
            @click="ShowImg = index"
            :class="['inst', ShowImg == index ? 'instActv' : '']"
          ></div>
        </div>
      </div>
    </div>
    <div
      class="content"
      :style="{ width: isPhone ? screenWidth - 30 + 'px' : '' }"
    >
      <div class="details" v-for="category in categoryList" :key="category.id">
        <div class="details-title">
          <!-- <span class="title1">V{{ category.category.name }}</span> -->
          <span class="title2">{{ category.category.ename }}</span>
        </div>
        <div class="essay-list">
          <div
            class="essay-content"
            v-for="article in category.detail"
            :key="article.id"
            @click="gotoDetail(article)"
          >
            <div class="left">
              <img
                :src="article.img"
                alt=""
                :style="{
                  width: isPhone ? screenWidth * 0.5 + 'px' : '',
                  height: isPhone ? screenWidth * 0.4 + 'px' : '',
                }"
              />
              <span class="arrow-bottom"></span>
            </div>
            <div class="right">
              <div class="top">
                <span>{{ article.Category.name }}</span>
                <span class="divider"></span>
                <span>{{ formatTime(article.UpdatedAt) }}</span>
              </div>
              <div class="title">{{ article.title }}</div>
              <div class="notes">
                {{ article.desc }}
              </div>
            </div>
          </div>
          <!-- <div class="essay-content">
            <div class="left">
              <img src="../assets/images/example.jpg" alt="" />
              <span class="arrow-bottom"></span>
            </div>
            <div class="right">
              <div class="top">
                <span>HOME</span>
                <span class="divider"></span>
                <span>1 year ago</span>
              </div>
              <div class="title">中国品牌上市</div>
              <div class="notes">
                5月12日，由柠悦轻医美携手TSL|谢瑞麟珠宝开展的柠悦CHIC
                LADY打卡第四站活动圆满举行。本次活动以”完美轮廓Earring
                Party”为主题，旨在通过现场授课、现场搭配、现场提问互动等形式，探索风格各异的耳饰与不同脸型之间的最佳搭配诀窍，使都市精致女性掌握面部轮廓完美修饰秘籍。本次活动邀请了国内知名时尚媒体、时尚达人和柠悦高级会员等近40位嘉宾，并获得了大家的一致好评。
              </div>
            </div>
          </div> -->
        </div>
      </div>
    </div>
  </MainLayout>
</template>

<script setup>
import MainLayout from "@/components/MainLayout.vue";
import { useRouter } from "vue-router";
import { ref, onMounted, onBeforeUnmount } from "vue";
import { getArticleList, getBanner } from "@/api/article";
import { formatTime } from "@/utils/time";
import banner1 from "../assets/images/banner1.jpg";
import banner2 from "../assets/images/banner2.png";
import banner3 from "../assets/images/banner3.png";
import banner4 from "../assets/images/banner4.png";

const router = useRouter();
const categoryList = ref([]);
const imgList = ref([
  { src: banner1 },
  { src: banner2 },
  { src: banner3 },
  { src: banner4 },
]);
const ShowImg = ref(0); // 当前展示的图片
let flag = true; // 用来节流防止重复点击
let start = null; // 自动执行下一张定时器
const isPhone = ref(false);
const screenWidth = window.innerWidth || 1080;

onMounted(async () => {
  isPhone.value = screenWidth <= 768 ? true : false;
  window.scrollTo({
    top: 0,
    behavior: "smooth", // 添加平滑滚动效果
  });
  const res = await getBanner();
  imgList.value = res?.data;
  setTimeoFun();
});
onBeforeUnmount(() => {
  clearInterval(start);
});

const getMenu = async (menu) => {
  for (let item of menu) {
    const res = await getArticleList(item.id, 4, 0);
    if (res.data?.length) {
      categoryList.value.push({
        category: item,
        detail: res.data,
      });
    }
  }
};

const gotoDetail = (article) => {
  router.push(`/article?id=${article.ID}&categoryId=${article.Category.id}`);
};

const jumpDetail = () => {
  console.log(imgList.value[ShowImg.value]);
  const cur = imgList.value[ShowImg.value];
  if (cur.jump) {
    window.open(cur.jump);
  }
};

const gotoCategory = (id) => {
  router.push(`/category?id=${id}`);
};

const MouseFun = (type) => {
  type == "enter" ? clearTimeout(start) : setTimeoFun();
};
const setTimeoFun = () => {
  start = setInterval(() => {
    NextFun();
  }, 2000);
};
// 这里通过额外封装的节流函数触发 PrevFun() 和 NextFun(),以达到防止重复点击的效果
const throttle = (fun) => {
  if (flag) {
    flag = false;
    fun(); // 此为模板中传递进来的PrevFun()或NextFun()函数
    setTimeout(() => {
      flag = true;
    }, 500); // 节流间隔时间
  }
};
// 上一张
const PrevFun = () => {
  if (ShowImg.value !== 0) {
    ShowImg.value--;
  } else {
    ShowImg.value = imgList.value?.length - 1;
  }
};
// 下一张
const NextFun = () => {
  const length = imgList.value?.length - 1;
  if (ShowImg.value !== length) {
    ShowImg.value++;
  } else {
    ShowImg.value = 0;
  }
};
</script>
<style lang="less" scoped>
.banner {
  display: flex;
  justify-content: center;
  padding: 10px;
}
.swiper-box {
  position: relative;
  width: 1080px;
  height: 400px;
  border: 1px solid #ccc;
  // box-sizing: border-box;
  .imgCss {
    position: absolute;
    left: 0px;
    top: 0px;
    width: 100%;
    height: 100%;
    opacity: 0;
    transition: 0.8s; /* 淡入淡出过渡时间 */
    cursor: pointer;
  }
  /* 图片选中样式(继承上方默认样式) */
  .ShowCss {
    opacity: 1;
  }
  /* 两个按钮共有的样式,也可直接使用箭头图片替代 */
  .leftBtn,
  .rightBtn {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    width: 30px;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    // background: rgba(109, 109, 109, 0.445);
    background: url(../assets/images/prev-arrow.png) no-repeat left center;
    color: #fff;
    border-radius: 50%;
    cursor: pointer;
    font-size: 12px;
    font-weight: 500;
  }
  .leftBtn {
    left: 10px;
  }
  .rightBtn {
    right: 10px;
    transform: rotate(180deg);
  }
  /* 下方指示器盒子 */
  .instBox {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    bottom: 10px;
    display: flex;
  }
  /* 小圆点 */
  .inst {
    width: 10px;
    height: 10px;
    border: 1px solid #ccc;
    margin-right: 8px;
    background: #fff;
    border-radius: 50%;
    cursor: pointer;
  }
  .inst:last-child {
    margin-right: 0px;
  }
  .instActv {
    border: 1px solid #ff0000;
    background: #ff0000;
  }
}
.content {
  width: 1080px;
  margin: 0 auto;
  box-sizing: content-box;
  .details {
    margin-top: 30px;
    border-bottom: 1px solid #ccc;
    &:first-child {
      margin: 0;
    }
    &-title {
      background-color: #dddddd;
      height: 60px;
      color: #000;
      font-family: "futura", "helvetica", "黑体";
      font-size: 30px;
      line-height: 60px;
      // .title1 {
      //   letter-spacing: 8px;
      // }
      .title2 {
        letter-spacing: 0.5px;
        // margin-left: 20px;
      }
    }
    .essay-list {
      // width: 1200px;
      // margin: 0 auto;
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-row-gap: 20px;
      grid-column-gap: 15px;
      .essay-content {
        padding: 20px 0;
        display: flex;
        height: 178px;
        cursor: pointer;
        .left {
          display: inline-block;
          position: relative;
          img {
            width: 270px;
            height: 172px;
          }
          .arrow-bottom {
            font-size: 0;
            line-height: 0;
            position: absolute;
            right: -6px;
            bottom: -3px;
            overflow: hidden;
            width: 0;
            height: 0;
            border: 0 dashed transparent;
            border-width: 40px;
            border-right-width: 0;
            border-bottom-style: solid;
            border-bottom-color: #85e3cb;
          }
        }
        .right {
          display: inline-flex;
          flex-direction: column;
          margin-left: 30px;
          flex: 1;
          .top {
            display: flex;
            align-items: center;
            font-size: 12px;
            height: 12px;
            color: gray;
            .divider {
              margin: 0 8px;
              height: 100%;
              width: 1px;
              background-color: gray;
            }
          }
          .title {
            font-weight: 600;
            font-size: 20px;
            margin: 10px 0;
            text-align: left;
          }
          .notes {
            flex: 1;
            font-size: 14px;
            line-height: 28px;
            text-align: left;
            color: #000;
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            // -webkit-line-clamp: 4;
          }
        }
      }
    }
  }
}
@media screen and (max-width: 768px) {
  .essay-list {
    display: flex !important;
    flex-direction: column;
    gap: 10px;
    .essay-content {
      flex-direction: column;
      height: auto !important;
      border: 1px solid #e3e3e3;
      padding: 10px 0 0 0 !important;
      .left {
        display: flex !important;
        justify-content: center;
      }
      .right {
        margin: 0 !important;
        padding: 10px;
        .top {
          justify-content: center;
        }
      }
      .arrow-bottom {
        display: none;
      }
    }
  }
}
</style>
